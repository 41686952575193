import React, { Component, useState, useEffect, } from 'react'
// import disabled from '../images/disabled.png'
// import modalbg from '../images/modalbg-min.png'
import Header from "./Header";
import Footer from "./Footer";
import WalletModal from "./WalletModal"
import PlaceholdingLoader from './PlaceholdingLoader'
import Slider from "@material-ui/core/Slider";
import { Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom"
import BigNumber from 'bignumber.js';
// import logo from '../images/logo.png'
import eth from '../images/eth.png'
 import bnb from '../images/bnb.png'
// import busd from '../images/busd.png'

// import reward_img from '../images/reward_img.png'
// import graph_img from '../images/graph_img.png'
// import star from '../images/star.png'

import logo from '../images/logo1.png';

import aboutimg from '../images/image1.png';
import rewardimg from '../images/2.png';
import rewardimg1 from '../images/1.png';
import rewardimg2 from '../images/3.png';

import rewardpuzzle from '../images/2a.png';
import rewardpuzzle1 from '../images/Group 55.png';
import rewardpuzzle2 from '../images/Group 56.png';

import whitecircle from '../images/sidecircle1.PNG';
import graycircle from '../images/grayimg1.png';

import puzzle from '../images/puzzle.png';

import sidearrow from '../images/arrow1.png';

import diamond from '../images/diamond2.png';

import rectangle from '../images/Rectangle 6.png';

import sidepuzzle from '../images/Path 11.png';
import sidepuzzle1 from '../images/Group 9.png';
import sidepuzzle2 from '../images/Path 9.png';
import sidepuzzle3 from '../images/Path 10.png';

import blurpuzzle from '../images/Path 24.png';

import { useWeb3 } from "../hooks/useWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../hooks/useWallet';
import toast, { Toaster } from 'react-hot-toast';
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS, plan } from "../hooks/UseStake"
import { CHAINS } from "../config/env"
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance, searchedValue, toFixedNumber } from "../hooks/UseContract"
import { ONEDAYINSECONDS } from '../config/env';
import { getAccount } from "../hooks/useAccount"
import { rewardTokenDetails, irenaLpToken, stakeTokens } from '../config/Farms';

// import banner_img from '../images/banner_img.png'
// import logo_coin from '../images/logo_coin.png'


import ProgressBar from "@ramonak/react-progress-bar";

import leaf from '../images/leaf.png'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
    const [accountInfo, setAccountInfo] = useState("");
    const [walletConnect, set_walletConnect] = useState("")
    const [poolLength, setPoolLength] = useState(0);
    const [poolLimit, setPoolLimit] = useState([]);
    const [key, setKey] = useState('first');
    const [lpsymbol, setlpsymbol] = useState({})
    const [wallet, setShowWallet] = useState(false)
    const [userTokenBalance, setUserTokenBalance] = useState("");
    const [perDayReward, setPerDayReward] = useState()
    const [reward, setReward] = useState();
    const [loading, setLoading] = useState(false);
    const [mark, setMark] = useState([]);
    const [valueone, setValueOne] = React.useState([30]);
    const [valuetwo, setValueTwo] = React.useState([0]);
    const [valuethree, setValueThree] = React.useState([20]);
    const [contentLoading, setContentLoading] = useState(false);
    const [selectStakeToken, setSelectStakeToken] = useState(stakeTokens[0]);
    const [selectRewardToken, setSelectRewardToken] = useState(rewardTokenDetails[0]);
    const [rewardListToken,setRewardListToken] = useState(rewardTokenDetails);
    const [selectDayApy,setSelectDayApy] = useState();
   const [selectedDayApy,setSelectedDayApy] = useState();



    const options = {

        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        center: true,
        // navText: ['<i class="fa fa-long-arrow-left" aria-hidden="true"></i>','<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 3,
            },
            1200: {
                items: 3,
            }
        },
        
    };



    function addtabclass() {
        document.getElementById("tabid").classList.add("completed")
    }

    useEffect(() => {
        // showloader()
        getData();
        if (sessionStorage.accountInfo) {
            setAccountInfo(sessionStorage.getItem("accountInfo"))
        } else {
            console.log("SORRY...No Wallet Address")
        }
    }, [accountInfo]);

    const logOut = async () => {
        setAccountInfo("");
        sessionStorage.removeItem("accountInfo");
        window.location.reload()
    }


    const setTabKey = (value) => {
        setKey(value)
    }

    const handleChange = (event, newValue) => {
        setValueOne(newValue);
    };

    function valuetextone(valueone) {
        return `${valueone}`;
    }


    const handleChangeTwo = (event, newValue) => {
        console.log('handleChangeTwo_handleChangeTwo',newValue);
        poolDetails(newValue)
        setValueTwo(newValue);
        const selecyApy = selectDayApy.filter((el)=> parseInt(el.lable) == parseInt(newValue));
    console.log("handleChangeTwo..",selecyApy)
    setSelectedDayApy(selecyApy[0].value)
    };

    function valuetexttwo(valuetwo) {
        return `${valuetwo}`;
    }

    const handleChangeThree = (event, newValue) => {
        setValueThree(newValue);
    };

    function valuetextthree(valuethree) {
        return `${valuethree}`;
    }

    const getData = async () => {
        const userbalance = accountInfo ? await getUserBalance(irenaLpToken, getAccount()) : 1000;
        // console.log("userBalance", userbalance,irenaLpToken)
        setUserTokenBalance(userbalance)
        setValueOne(0);
        var arr = []
        var data = await getPoolLength()
        // console.log("data", data);
        if (data) setPoolLength(data);
        var pool = await getPoolInfoLimit(0, data)
        console.log("firstPool", data)
        if (pool) {
            var poolArr = [];
            let daysApy = []
            for (let [index, value] of pool.entries()) {
                if (index <= 2) {
                    var poolDetail = await plan(index)
                    console.log("poolDetail", poolDetail);
                    poolArr.push(poolDetail);
                }

            }
            setPoolLimit(poolArr);
            console.log(poolArr, 'pool')
            setValueTwo(Math.floor(parseInt(poolArr[0]?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24)))
            poolArr.map((val, index) => {
                var marks = {
                    lable: `${Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}Days`,
                    value: Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))
                }
                var selectDayApy= {
                    lable : `${Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}Days`,
                    value : ((parseInt(val?.UmudiPerBlock) / 10 ** 9) * Number((val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))/ 10 ** 9)
                  }
                  daysApy.push(selectDayApy)
                arr.push(marks)
            })
            setMark(arr)
            setSelectDayApy(daysApy)
            setLoading(true);
              console.log("valuetwo in getdata",arr)
            await poolDetails(valuetwo)
            //   hideloader()
        }
    }

    const poolDetails = async (value) => {
        console.log("poolDetails Calc",value)
        setContentLoading(true);
        if (value) {
            const lockedPeriod = (1000 * 60 * 60 * 24 / 1000) * (value);
            console.log("filterData...", poolLimit, "-------------", lockedPeriod);
            const filterData = await searchedValue(poolLimit, lockedPeriod);
              console.log("filterData...", filterData[0]);
            //   var RewardTokenInfo = await getRewardTokenInfo();
            var poolday = ((parseInt(lockedPeriod) / ONEDAYINSECONDS))
            // setpoolDays(poolday.toFixed());

            var TokenInfo = await UseTokenInfo(irenaLpToken)
            // setTokenInfo(TokenInfo);

            var apy = ((filterData[0]?.UmudiPerBlock / 10 ** TokenInfo?.decimals) * Number(poolday)).toFixed(2);

            //   console.log("apy", apy, "valueone", valueone, "UmudiPerBlock", filterData[0]?.UmudiPerBlock, " TokenInfo decimals", TokenInfo?.decimals, "RewardTokenInfo",RewardTokenInfo);

            const amount = ((apy * (parseInt(userTokenBalance * valueone) / 100))) * 10 ** TokenInfo?.decimals;
            const fixedAmount = await toFixedNumber(amount)
            //   console.log("TOFIXEDNUMBER", fixedAmount);
            setPerDayReward(amount);
            //   console.log("apy DATA....", amount, "=====", amount * valueone);
            //   const rewardReturn = await getUSDTprice(fixedAmount, irenaLpToken);
            //   console.log("rewardReturn..", rewardReturn);
            //   setReward(rewardReturn ? (rewardReturn / 10 ** RewardTokenInfo?.decimals).toFixed(14) : "0");
            // setReward(fixedAmount / 100);
            setContentLoading(false)
            //   hideloader()
        } else {
            //   console.log("NO VALUEs........");
            //   hideloader()

        }

    }

    //   const showloader = async () => {
    //     document.getElementById("loader_div").style.display = "block";
    //   }

    //   const hideloader = async () => {
    //     document.getElementById("loader_div").style.display = "none";
    //   }
    const calculate = async () => {
        const stateDetail = selectStakeToken
        const rewardDetail = selectRewardToken
        const amount = valueone * 10 ** stateDetail?.decimal

        const getRewardTokens = await getUSDTprice(amount, stateDetail?.address, rewardDetail?.address);
        console.log("getRewardTokens", getRewardTokens, amount, stateDetail, rewardDetail);
        console.log("percenrage", getRewardTokens / stateDetail?.decimal);
        console.log("percenrage1", getRewardTokens / parseInt(10 ** stateDetail?.decimal));
        // const calculateReward = ().toFixed(7)
        setReward(getRewardTokens / parseInt(10 ** stateDetail?.decimal));
    }
    const selectStakeTokens = async (eventKey) => {
        // console.log(" set selectStakeTokens","----",JSON.parse(eventKey));
        let selectValue = JSON.parse(eventKey);
        if(selectValue.token == "USDT"){
            var filteredArray = rewardTokenDetails.filter(function(e) { return e.token !== 'PAYU' });
            console.log('found_rewardTokenDetails',filteredArray);
            setRewardListToken(filteredArray)
            setSelectRewardToken()
          }else if(selectValue.token == "IRENA") {
            const found = rewardTokenDetails.filter(element => element.token == "IRENA" || element.token == "PAYU"  ||   element.token == "USDT");
            console.log('found_rewardTokenDetails',found,rewardTokenDetails);
            setRewardListToken(found)
            setSelectRewardToken()
          }
        }
    
    return (
        <div className='home1'>
            <section>
                {/* <div className='showloader minwidth-534 text-center' id="loader_div">
        <div className='loader ' >
          <img src={leaf} className="spin" />
        </div>
      </div> */}
            </section>
            <div className='landing_bg'>
                <div className='home_page'>
                    <Header />

                    <section className='container container_custom home_sec_1 pt-main-top'>
                    <span className='blugradient'></span> 
                    <span className='greengradient'></span>
                    <span className='orangegradient'></span>
                    <div className='homeindex'>
                    <img className='img-fluid sidepuzzle' src={sidepuzzle} alt="puzzles"/>
                    <img className='img-fluid sidepuzzle1' src={sidepuzzle1} alt="puzzles"/>
                    <img className='img-fluid sidepuzzle2' src={sidepuzzle2} alt="puzzles"/>
                    <img className='img-fluid sidepuzzle3' src={sidepuzzle3} alt="puzzles"/>
                        <div className='row align-items-center justify-content-end py-5'>
                            <div className='col-12 col-md-12'>
                                <div className='sectionmaxwidth'>
                                    <h3 className='text_yellow banner_txt_yel text-md-center'>IRENA</h3>
                                    <div className='d-md-flex first'>
                                        <div className='content1'>
                                            <p className='banner_txt_desc'>Irena Coin Stake to Earn (S2E)
    </p>
                                            <p className='banner_txt_p'>You can earn Irena, Eth, Bnb, Matic and Usdt coins by staking your Irena Coin and Usdt coins.
</p>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center mt-4'>
                                                {
                                                    // (accountInfo == "" || accountInfo == null) ?
                                                    //     <button className="nav-link btn_yellow me-3" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> 
                                                    //     :
                                                    // <button className="nav-link btn_yellow me-3" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                                }                      
                                                  {/* <Link to="/staking" className="nav-link btn_yellow2" state={{ from: accountInfo }}>Stake Now</Link> */}

                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className='d-flex align-items-center mt-4'>
                        {
                                        (accountInfo == "" || accountInfo == null) ?
                                            <button className="nav-link btn_yellow me-3" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> :
                                            <button className="nav-link btn_yellow me-3" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                    }                        <Link to="/staking" className="nav-link btn_yellow" state={{ from: accountInfo }}>Stake Now</Link>
                       
                        </div> */}
                                </div>
                                <img className='img-fluid sidearrow' src={sidearrow} alt="puzzles"/>
                                <img className='img-fluid rectangle' src={rectangle} alt='puzzle'/>
                                <img className='img-fluid rectangle1' src={rectangle} alt='puzzle'/>
                            </div>
                            {/* <div className='col-12 col-md-7 mt-5 mt-md-0'>
                        <img src={banner_img} className="img-fluid" />
                        </div> */}
                        </div>
                        </div>
                        {/* <div className='row coin_row pb-5'>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Irena Finance </span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Irena Finance </span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Irena Finance </span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Irena Finance </span>
                        </div>
                    </div>
                </div> */}
                    </section>
                </div>
            </div>

            <div className='sec_2'>   
             <span className='redgradient'></span>
             <span className='blurgradient'></span>
           
                <section className='container container_custom home_sec_2'>
                <div className='homeindex'>

                 <div className='one'>
                <img className='img-fluid sidepuzzle' src={sidepuzzle} alt="puzzles"/>
                <img className='img-fluid whitecircle' src={whitecircle} alt="puzzles"/>
                <img className='img-fluid graycircle' src={graycircle} alt="puzzles"/>
                <img className='img-fluid puzzle' src={puzzle} alt="puzzles"/>
                </div>        
                    <div className='row align-items-center row_rev'>
                        <div className='col-12 col-md-6 mt-5 mt-md-0 pads'>
                            <div className='card1 card_shadow'>
                                <div className='card-body px-3 px-lg-4 py-4'>
                                    <div>
                                        <img src={logo} className="img-fluid logosec2" />

                                        <p className='text_sliver'>Investment Amount</p>
                                        <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                            <p>{valueone}</p>

                                        </div>
                                        <Slider
                                            getAriaLabel={() => "Temperature range"}
                                            value={valueone}
                                            onChange={handleChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetextone}
                                            // step={null}
                                            // marks={mark}
                                            max={100}
                                        />


                                    </div>

                                    <div className='mt-4 daysborder'>
                                        {/* <p className='text_sliver'>Days</p> */}
                                        <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                        <p className='text_sliver'>Days</p>
                                            <p>{valuetwo} Day</p>

                                        </div>

                                        <Slider
                                            //   getAriaLabel={() => "Temperature range"}
                                            //   value={valuetwo}
                                            //   onChange={handleChangeTwo}
                                            //   valueLabelDisplay="auto"
                                            //   getAriaValueText={valuetexttwo}
                                            getAriaLabel={() => "Temperature range"}
                                            value={valuetwo}
                                            onChange={handleChangeTwo}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetexttwo}
                                            step={null}
                                            marks={mark}
                                            max={3000}
                                        />
                                        <div className='flex_drop_div'>

<p>Stake Token :</p>

<Dropdown className='dropdwn_trna' onSelect={(eventKey) => { setSelectStakeToken(JSON.parse(eventKey));selectStakeTokens(eventKey) }}>
    <Dropdown.Toggle variant="success" id="dropdown-basic1">
        <img src={bnb} className="img-fluid me-1" />
        {selectStakeToken ? selectStakeToken.token : "Select Stake token"}
    </Dropdown.Toggle>

    <Dropdown.Menu>
        {stakeTokens && stakeTokens?.map((value, index) =>
            (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={bnb} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
        )}
    </Dropdown.Menu>
</Dropdown>
<p className='ms-2'>Reward Token :</p>
<Dropdown className='dropdwn_trna me-3' onSelect={(eventKey) => { setSelectRewardToken(JSON.parse(eventKey)) }}>
    <Dropdown.Toggle variant="success" id="dropdown-basic">
        <img src={bnb} className="img-fluid me-1" />
        {selectRewardToken ? selectRewardToken.token : "Select Reward token"}
    </Dropdown.Toggle>

    <Dropdown.Menu>
        {rewardListToken && rewardListToken?.map((value, index) =>
            (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={bnb} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
        )}
    </Dropdown.Menu>
</Dropdown>
</div>
                                    </div>

                                    <div className='mt-4'>

                                        
                                      

                                        <Slider
                                            getAriaLabel={() => "Temperature range"}
                                            value={reward ? parseInt(reward) : 0}
                                            onChange={handleChangeThree}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetextthree}
                                        />
                                          <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                                <p className='mid_value'>
                                                    
                                                    { 
                                                    // (selectStakeToken?.token =='USDT' || selectRewardToken?.token == 'USDT')
                                                       reward ? reward && (selectStakeToken?.token == 'IRENA' && selectRewardToken?.token == 'IRENA') ?  
                                                        (selectedDayApy / parseFloat(reward) * 100).toFixed(7) :
                                                            (selectStakeToken?.token =='USDT' && selectRewardToken?.token == 'USDT') ?
                                                            (selectedDayApy / parseFloat(reward) * 100).toFixed(7) :
                                                        (parseFloat(reward)/selectedDayApy * 100).toFixed(7) : 0} {selectRewardToken ? selectRewardToken.token : rewardTokenDetails[0].token
                                                    }
                                                    </p>

                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        {/* <button className="nav-link btn_yellow mt-4" onClick={()=> {calculate()}}>Calculate</button> */}
                                        <button className="nav-link btn_yellow mt-4" onClick={() => { calculate() }}>Calculate</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-12 col-md-6 ps-lg-5'>
                            <div className='roipuzzle'>
                            <h3 className='text_yellow banner_txt_yel'>IRENA <br></br>Calculator</h3>
                            <p className='banner_txt_p'>Irena Coin Move to earn usd IGE App (M2E)</p>
                            <p className='banner_txt_desc'>Download the IGE Move to earn application to your phone and earn Usd by moving and walking.
</p>
                            <div className='d-flex align-items-center mt-4'>
                                <Link to="/calculate" className="nav-link btn_yellow2">
                                    Learn about IRENA
                                    </Link>

                            </div>
                        </div>
                        </div>
                    </div>
                    <img className='img-fluid rectanglepuzzle' src={rectangle} alt="puzzles"/>
                    <img className='img-fluid sidepuzzle3' src={sidepuzzle3} alt="puzzles"/>
                    <img className='img-fluid rectanglepuzzle3' src={rectangle} alt="puzzles"/>
                    <img className='img-fluid blurpuzzle' src={blurpuzzle} alt="puzzles"/>
                    <img className='img-fluid puzzle1' src={puzzle} alt="puzzles"/>
                    </div>
                </section>
            </div>

            <div className='sec_3'>
            <span className='redgradient'></span>
            <span className='redgradient1'></span>
            <span className='blurgradient'></span>
                <section className='container container_custom home_sec_3 mt-5'>
              
                <div className='homeindex'>
                <img className='img-fluid rectanglepuzzle1' src={rectangle} alt="puzzles"/>
                <img className='img-fluid sidepuzzle' src={sidepuzzle} alt="puzzles"/>
                <img className='img-fluid puzzle1' src={puzzle} alt="puzzles"/>
                <img className='img-fluid puzzle2' src={puzzle} alt="puzzles"/>

                    <div className='row align-items-center'>

                        <div className='col-12 col-md-6'>
                            <div className='roipuzzle'>
                            <h3 className='text_yellow banner_txt_yel'> <br></br>STAKING COIN </h3>
                           
                            <p className='banner_txt_p'>Irena Coin Play to earn usd (P2E)</p>
                            <p className='banner_txt_desc'>Download our Irena Coin mobile game to your phone. Earn Usd by playing games.
</p>
                            <div className='d-flex align-items-center mt-4'>
                                <Link to="/rewards" className="nav-link btn_yellow2">
                                    {/* Learn about */}
                                     Rewards
                                    </Link>

                            </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-6 mt-5 mt-md-0 pads'>
                            {/* <img src={reward_img} className="img-fluid" /> */}
                            <div className='card1 card_shadow'>
                                <div className='card1-body px-3 mb-3 px-lg-4 py-4'>
                                    <img src={logo} className="img-fluid logosec2" />
                                    <div className='d-flex mt-4 duration'>
                                        <p className='duration_p'>Duration</p>
                                        {/* <a href='/staking' className="blue_a_new">Irena Finance </a> */}
                                    </div>

                                    <div className='row mt-2 mb-2'>
                                        <div className='col-md-6 col-sm-6 col-12 mt-2'>
                                            <div className='card progress_card'>
                                                <div className='card-body px-3 px-lg-4 py-3 text-center'>
                                                    <p className='progress_title'>Irena Finance </p>
                                                    <h5>90 Days</h5>
                                                    <ProgressBar completed="200" maxCompleted={400} height="9px" bgColor="#6CDC64" animateOnRender={true} isLabelVisible={false} />
                                                    <p className='mt-3 progress_title1'>12% APY</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-12 mt-2'>
                                            <div className='card progress_card'>
                                                <div className='card-body px-3 px-lg-4 py-3 text-center'>
                                                    <p className='progress_title'>Irena Finance </p>
                                                    <h5>365 Days</h5>
                                                    <ProgressBar completed="200" maxCompleted={400} height="9px" bgColor="#6CDC64" animateOnRender={true} isLabelVisible={false} />
                                                    <p className='mt-3 progress_title1'>27% APY</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='text-center mt-5 mb-3'>
                                        <Link to="/staking" className="nav-link btn btn_yellow3 mt-4" state={{ from: accountInfo }}>Stake Now</Link>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <img className='img-fluid sidepuzzle3' src={sidepuzzle3} alt="puzzles"/>
                    <img className='img-fluid rectanglepuzzle3' src={rectangle} alt="puzzles"/>

                    <img className='img-fluid blurpuzzle' src={blurpuzzle} alt="puzzles"/>
                    <img className='img-fluid graycircle' src={graycircle} alt="puzzles"/>
                <img className='img-fluid puzzle3' src={puzzle} alt="puzzles"/>
                    </div>
                </section>
            </div>

            {/* <section className='container container_custom home_sec_4 mt-5'>
        <h3 className='text_yellow banner_txt_yel text-center'>How much can I <span className='text-white'>Earn</span></h3>
       <div className='row'>
        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
        <p className='text_silver_desc text-center'>Irena Finance  has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

        </div>
       </div>
              
                <div className='row align-items-center'>
                   
                    <div className='col-12 col-md-6 mt-3'>
                        <p className='banner_txt_desc'>Irena Finance  has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                       
                    </div>
                    <div className='col-12 col-md-6 mt-3'>
                        <p className='banner_txt_desc'>Irena Finance  has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                       
                    </div>
                    <div className='col-12'>
                    <div className='d-flex align-items-center mt-4 justify-content-center'>
                        <Link to="/staking" className="nav-link btn_yellow" state={{ from: accountInfo }}>Start Earning Now</Link>
                       
                        </div>
                    </div>
                   
                </div>
        </section> */}


            {/* <section className='container container_custom home_sec_5 mt-5'>
                <div className='row align-items-center row_rev'>
                   
                  
                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                      <img src={graph_img} className="img-fluid" />

                    </div>
                    <div className='col-12 col-md-6'>
                        <h3 className='text_yellow banner_txt_yel'>Earn a fixed <span className='text-white'>APY</span></h3>
                        <p className='banner_txt_desc'>Irena Finance  has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <div className='d-flex align-items-center mt-4'>                     
                        </div>
                    </div>
                </div>
        </section> */}


            {/* <section className='container container_custom home_sec_6 mt-5 pt-4'>
                <h3 className='text_yellow banner_txt_yel text-center'>Irena Finance  <span className='text-white'>Pricing</span></h3>
                <div className='row'>
                    <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                        <p className='text_silver_desc text-center'>Irena Finance  has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                    </div>
                </div>

                <div>
                    <div className='row mt-5 carousel_home_theme pb-5'>
                        <div className='col-12 col-md-12 col-lg-11 col-xl-10 mx-auto'>
                            <OwlCarousel className='owl-theme pb-3' loop margin={30} nav {...options}>
                                {
                                    poolLimit && poolLimit.map((val, i) =>
                                        <div class='item'>
                                            <div className='card card_carusel'>
                                                <img src={star} className="img-fluid star_img_carousel" />
                                                <div className='card-body py-4'>
                                                    <p className='text_price'>$<span>{((parseInt(val?.UmudiPerBlock) / 10 ** 18) * Number((val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))).toFixed(2)}</span></p>
                                                    <p className='days_grey_price'>{Math.floor(parseInt(val.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))} days</p>
                                                    <ul className='card_ul_car ps-0'>
                                                        <li className='mb-2'>
                                                            <i className='fa fa-check check_yellow me-2' />


                                                            <span>10 aliquet dui quis</span>
                                                        </li>
                                                        <li className='mb-2'>
                                                            <i className='fa fa-check check_yellow me-2' />


                                                            <span>10 aliquet dui quis</span>
                                                        </li>
                                                        <li className='mb-2'>
                                                            <i className='fa fa-check check_yellow me-2' />


                                                            <span>10 aliquet dui quis</span>
                                                        </li>
                                                        <li className='mb-2'>
                                                            <i className='fa fa-check check_yellow me-2' />


                                                            <span>10 aliquet dui quis</span>
                                                        </li>
                                                    </ul>
                                                    <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </OwlCarousel>
                        </div>
                    </div>

                </div>

            </section> */}


            <div className='sec_reward'>
            <span className='blugradient'></span>
            <span className='orangegradient'></span>
                <section className='container container_custom'>
                <div className='homeindex'>
                <img className='img-fluid rectanglepuzzle1' src={rectangle} alt="puzzles"/>
                <img className='img-fluid rectanglepuzzle2' src={rectangle} alt="puzzles"/>
              
                    <div className='sectionlast1'>
                        
                        <div className='roipuzzle'>
                        <h3 className='text_yellow banner_txt_yel text-center'>REWARDS</h3>
                       <div className='row'>
                        <div className='col-md-6 m-auto'>
                            <p className='banner_txt_p'>Irena Finance  is simply dummy text of the printing</p>
                        </div>
                        </div>
                        </div>
                        <div className='row mt-md-5 justify-content-center'>
                        <div className='col-md-4 col-sm-6 text-center rewardcard1'>
                             <div>
                                <img className='img-fluid reward1' src={rewardimg} alt='reward'/>
                                <p className='mt-3 text_bold_blky'>0x9eeh4236mop878</p>
                                <div className='shadowcard'>
                                <div className='mt-5'>
                                <div className='card'>
                                <div className='card-body'>
                                <img className='img-fluid rewardpuzzle' src={rewardpuzzle} alt='reward'/>
                                <p className='puzzleborder text_bold_blky'>Irena Finance</p>
                                <h5><img className='img-fluid diamond' src={diamond} alt='reward'/> 180</h5>
                                <p className='days'>Days</p>
                                </div>
                                </div>
                                </div>
                                </div>
                             </div>
                        </div>
                        <div className='col-md-4 col-sm-6 text-center'>
                             <div>
                                <img className='img-fluid reward1 mt-3' src={rewardimg1} alt='reward'/>
                                <p className='mt-3 text_bold_blky'>0x9eeh4236mop878</p>
                                <div className='shadowcard'>
                                <div className='mt-5'>
                                <div className='card'>
                                <div className='card-body'>
                                <img className='img-fluid rewardpuzzle' src={rewardpuzzle1} alt='reward'/>
                                <p className='puzzleborder text_bold_blky'>Irena Finance</p>
                                <h5><img className='img-fluid diamond' src={diamond} alt='reward'/> 365</h5>
                                <p className='days'>Days</p>
                                </div>
                                </div>
                                </div>
                                </div>
                             </div>
                        </div>
                        <div className='col-md-4 col-sm-6 text-center rewardcard1'>
                             <div>
                                <img className='img-fluid reward1' src={rewardimg2} alt='reward'/>
                                <p className='mt-3 text_bold_blky'>0x9eeh4236mop878</p>
                                <div className='shadowcard'>
                                <div className='mt-5'>
                                <div className='card'>
                                <div className='card-body'>
                                <img className='img-fluid rewardpuzzle' src={rewardpuzzle2} alt='reward'/>
                                <p className='puzzleborder text_bold_blky'>Irena Finance</p>
                                <h5><img className='img-fluid diamond' src={diamond} alt='reward'/> 90</h5>
                                <p className='days'>Days</p>
                                </div>
                                </div>
                                </div>
                                </div>
                             </div>
                        </div>
                        </div>

                        <div className='cardlast'>
                        <div className='card-body'>
                            <p>Irena Finance  has been the industry's standard dummy text ever since dummy 8000 points.</p>
                            </div>
                        </div>

                        <div>
                            <button className="nav-link btn_yellow2 mt-4">About Reward</button>
                        </div>
                    </div>
                    </div>
                </section>
            </div>


            <div className='sec_about'>
            <span className='greengradient'></span>
                <span className='greengradient1'></span>
                <span className='greengradient2'></span>
                <span className='greengradient3'></span>
                <section className='container container_custom'>
                <div className='homeindex'>
                <img className='img-fluid rectanglepuzzle1' src={rectangle} alt="puzzles"/>
                <img className='img-fluid rectanglepuzzle2' src={rectangle} alt="puzzles"/>
                    <div className='sectionlast2'>
                        <div className='roipuzzle'>
                        <h3 className='text_yellow banner_txt_yel text-center'>ABOUT APP</h3>
                        <div className='row'>
                        <div className='col-md-6 m-auto'>
                            <p className='banner_txt_p'>Irena Coin Stake to Earn (S2E) <br></br>

You can earn Irena, Eth, Bnb, Matic and Usdt coins by staking your Irena Coin and Usdt coins.

</p>
                        </div>
                        </div>
                        </div>
                        <div className='i1'>
                            <img src={aboutimg} className="img-fluid aboutimg" />
                            <div className='m2e'>
                                <p className='irena'>Irena Stake to</p>
                                <h5>Earn USD (S2E)</h5>
                            </div>
                            <div className='m2e1'>
                                <p className='irena'>Irena Move to</p>
                                <h5>Earn USD (M2E)</h5>
                            </div>
                            <div className='m2e2'>
                                <p className='irena'>Irena Drive to</p>
                                <h5>Earn USD (D2E)</h5>
                            </div>
                            <div className='m2e3'>
                                <p className='irena'>Irena Play to</p>
                                <h5>Earn USD (P2E)</h5>
                            </div>
                        </div>

                    </div>
                    </div>
                </section>
            </div>


            <div className='sec_last'>
            <span className='orangegradient'></span>
            <span className='greengradient'></span>
                <section className='container container_custom'>
                <div className='homeindex'>
                <img className='img-fluid rectanglepuzzle1' src={rectangle} alt="puzzles"/>
                <img className='img-fluid rectanglepuzzle2' src={rectangle} alt="puzzles"/>
                    <div className='sectionlast'>
                        <div className='roipuzzle'>
                        <h3 className='text_yellow banner_txt_yel text-center'>START IN SECONDS</h3>

                        <div>
                            <p className='banner_txt_p'>Irena Finance  is simply dummy text of the printing</p>
                        </div>
                        </div>
                        {/* <div>
                            <button className="nav-link btn_yellow2 mt-1">Connect Wallet</button>
                        </div>
                         */}

{
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button className="nav-link btn_yellow2 mt-1" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> :
                                        <button className="nav-link btn_yellow2 mt-1" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                }
                    </div>
                    </div>
                </section>
            </div>


            <Footer />
            {wallet && <WalletModal onDismiss={() => setShowWallet(false)} />}
        </div>

        // <section className='land pb-5'>
        //     <div className='showloader minwidth-534 text-center' id="loader_div">
        //         <div className='loader ' >
        //             <img src={loader} className="spin" />
        //             <img src={leaf} className="leaf" />
        //         </div>

        //     </div>
        //     <header className='container'>
        //         <div className='d-flex align-items-center justify-content-between'>
        //         <img src={logo} />
        //         <Link to="/usdi" className='link_to_stake'>Stake USDI</Link>
        //         </div>
        //     </header>
        //     <div><Toaster /></div>
        //     <div className='container'>


        //         <Tab.Container id="left-tabs-example" activeKey={key}
        //             onSelect={(k) => setKey(k)}>
        //             <Row className='align-items-center'>
        //                 <Col lg={3} className="text-end">
        //                     <Nav variant="pills" className="flexcolumn">
        //                         <Nav.Item className='completed'>
        //                             <Nav.Link eventKey="first" >1</Nav.Link>
        //                         </Nav.Item>
        //                         <Nav.Item id="tabid" className=''>
        //                             <Nav.Link eventKey="second" >2</Nav.Link>
        //                         </Nav.Item>
        //                         <Nav.Item id="tabid" className=''>
        //                             <Nav.Link eventKey="third" onClick={getData}>3</Nav.Link>
        //                         </Nav.Item>
        //                     </Nav>
        //                 </Col>
        //                 <Col lg={9}>
        //                     <Tab.Content>
        //                         <Tab.Pane eventKey="first">

        //                             <div className='tab-cont'>

        //                                 <div className='text-center'>

        //                                     <div className='col-md-7 minwidth-534'>
        //                                         <div className='head text-center'>
        //                                             <h2 className='upper'>Connect wallet</h2>
        //                                             <p>Welcome to Irena Green Energy World</p>
        //                                         </div>
        //                                         <div className='circlesec'>
        //                                             <div className='centertext'>
        //                                                 <img src={center} className="leafimg" />
        //                                             </div>
        //                                             <img src={disabled} className="stepbg" />
        //                                             <div className='steps'>
        //                                                 <button onClick={() => setTabKey('second')} className='step1btn' >
        //                                                     <img src={file} />
        //                                                     <p>Connect</p>
        //                                                     <img src={arrow} className="arrow" />

        //                                                 </button>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </Tab.Pane>
        //                         <Tab.Pane eventKey="second">
        //                             <div className='tab-cont'>
        //                                 <div className='text-center'>
        //                                     <div className='col-md-7 minwidth-534'>
        //                                         <div className='head text-center'>
        //                                             <h2 className='upper'>Connect wallet</h2>
        //                                             <p>Irena Green Energy World</p>
        //                                         </div>
        //                                         <div className='circlesec'>
        //                                             <div className='centertext'>
        //                                                 <img src={center} className="leafimg" />
        //                                             </div>
        //                                             <img src={disabled} className="stepbg" />
        //                                             <div className='steps1'>
        //                                                 <button onClick={async () => { MetaMask(); await getData(); showloader(); addtabclass() }} className='step1btn'>
        //                                                     <img src={meta} className="meta" />
        //                                                     <p>Meta Mask</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                             <div className='steps2'>
        //                                                 <button onClick={async () => { enableWallet(); await getData(); addtabclass(); }} className='step1btn'>
        //                                                     <img src={wall} className="wall" />
        //                                                     <p>Wallet Connect</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                             <div className='steps3 steps_new1'>
        //                                                 <button onClick={async () => { MetaMask(); await getData(); showloader();addtabclass() }} className='step1btn'>
        //                                                     <img src={trust} className="wall" />
        //                                                     <p>DApps</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                             <div className='steps4 steps_new1'>
        //                                                 <button onClick={async () => { Binance(); await getData(); showloader();addtabclass() }} className='step1btn'>
        //                                                     <img src={binance} className="wall" />
        //                                                     <p>Binance Wallet</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </Tab.Pane>
        //                         <Tab.Pane eventKey="third" className='laststep'>

        //                             <OwlCarousel items={1} dots={false} nav={true} className="owl-theme owl_coin minwidth-534">

        //                                 {poolLimit
        //                                     && poolLimit.map((val, index) => <Stakecard datas={val} pid={index} lpsymbols={lpsymbol[index]} accountInfo={accountInfo} />
        //                                     )}
        //                             </OwlCarousel>
        //                         </Tab.Pane>
        //                     </Tab.Content>
        //                 </Col>
        //             </Row>
        //         </Tab.Container>

        //     </div>
        // </section>
    )
}

export default Home
